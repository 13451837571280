
import { api } from "@/api";
import { readLabels, readModel } from "@/store/model/getters";
import { Component, Vue } from "vue-property-decorator";

import { readHasAdminAccess, readToken } from "@/store/main/getters";

import { authUrl, customerApiUrl } from "@/env";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

@Component({
  components: {},
})
export default class Api extends Vue {
  public bearerToken: string = "";
  public showToken: boolean = false;
  public credentialsError: string = "";
  public id: number = 1;
  public loading: boolean = false;
  public loadingCredentials: boolean = false;
  public credentials: any = null;
  public clients: any = null;
  public curlString: string = "";
  public authString: string =
    'curl -k -X POST -H "Content-Type: application/x-www-form-urlencoded" -u ' +
    "'<CLIENT_ID>:<CLIENT_SECRET>'" +
    " -d grant_type=client_credentialsauthUrl " +
    customerApiUrl +
    "/oauth2/token";
  public authUrl: string = authUrl;
  public copyIcon: string = "content_copy";
  public clientId: string = "Generating...";
  public clientSecret: string = "Generating...";

  public async createAPIToken() {
    const res = await api.createBearerToken(
      this.token,
      this.credentials.client_id,
      this.credentials.client_secret,
    );
    this.bearerToken = res.data;
    this.showToken = true;
  }

  public toggleIcon() {
    // Set icon to check
    this.copyIcon = "check";

    // Reset icon to copy after 1 second
    setTimeout(() => {
      this.copyIcon = "content_copy";
    }, 1000);
  }

  public copyText() {
    this.toggleIcon();
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = this.bearerToken;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  public parsedDate(ts) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY h:mm A");
  }

  public async getClients() {
    const res = await api.getClients(
      this.token,
      parseInt(this.$router.currentRoute.params.workspaceid, 10),
    );
    this.clients = res.data;
  }

  public async mounted() {
    await this.getClients();
  }

  public async newCredentials() {
    this.loadingCredentials = true;
    this.credentialsError = "";
    this.showToken = false;
    this.bearerToken = "";
    await api
      .createClientCredentials(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
      )
      .then((res) => {
        this.credentials = {
          client_name: res.data.client_name,
          client_id: res.data.client_id,
          client_secret: res.data.client_secret,
        };

        this.clientId = res.data.client_id;
        this.clientSecret = res.data.client_secret;
        this.getClients();
        this.loadingCredentials = false;
      })
      .catch((error) => {
        this.loadingCredentials = false;
        this.credentialsError = error.response;
        console.log("ERROR", this.credentialsError);
      });
  }

  public getInferenceCurl(modelId): string {
    const inferenceExample = '{"texts":["my first inference"]}';
    const inferenceURL = customerApiUrl + "/v2/models/" + modelId + "/inference";
    const result =
      'curl -X POST "' +
      inferenceURL +
      '" -H "accept: application/json" -H  "Authorization: Bearer <INSERT BEARER TOKEN HERE>' +
      '" -H  "Content-Type: application/json" -d ' +
      "'" +
      inferenceExample +
      "'";
    return result;
  }

  public async revokeCredentials(clientName) {
    const res = await api.revokeClientCredentials(
      this.token,
      parseInt(this.$router.currentRoute.params.workspaceid, 10),
      clientName,
    );
    this.clients = res.data;
  }

  /*    ________________________________________________     */
  /*                       GETTERS                           */
  /*    ________________________________________________     */
  get isAdmin() {
    return readHasAdminAccess;
  }

  get token() {
    return readToken(this.$store);
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  public goToDocumentation() {
    window.open("https://www.labelf.ai/docs/api/model-inference-api", "_blank");
  }
}
