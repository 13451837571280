
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";

@Component
export default class ConfusionMatrix extends Vue {
  @Prop({ default: false })
  public metrics!: IMetrics;
  @Prop({ default: false })
  public labels!: ILabel[];
  @Prop({ default: false })
  public predictions!: any;
  @Prop({ default: false })
  public labelsByIds!: any;
  @Prop({ default: false })
  public labelsByName!: any;
  @Prop({ default: false })
  public modelId!: any;
  @Prop({ default: false })
  public targetLabelId!: any;
  // public async mounted() {
  //   var myPlot = document.getElementById('plotly-cool');
  //   myPlot?.addEventListener("Click", function (data) {console.log(data)});
  // }

  // public handlePlotClick(data) {
  //   const header =
  //     "Predicted label:<" +
  //     this.labelsByName[data.points[0].y].name +
  //     "> Actual: <" +
  //     this.labelsByName[data.points[0].x].name +
  //     ">";
  //   this.confusion(
  //     this.labelsByName[data.points[0].y].id,
  //     this.labelsByName[data.points[0].x].id,
  //     header,
  //   );
  // }

  get labelCountList() {
    const res: number[] = [];
    this.metrics.keys.forEach((k) => {
      res.push(this.labelsByIds[k].counter);
    });
    return res;
  }
  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }

  public handlePlotClick(data) {
    const header = "Predicted label:<" + "test" + "> Actual: <" + "test" + ">";
    const points = data.points[0].pointIndex;
    // Case: predicted Other. Actual Target label
    if (points[0] === 1 && points[1] === 1) {
      this.confusion(parseInt(this.targetLabelId, 10), 0, header);
      // Case: predicted Target label. Actual Target label
      // } else if (points[0] === 1 && points[1] === 0) {
      //   console.log("case 2");
      //   this.confusion(parseInt(this.targetLabelId), parseInt(this.targetLabelId), header);
      //   // Case: predicted Target label. Actual Other
    } else if (points[0] === 0 && points[1] === 0) {
      this.confusion(0, parseInt(this.targetLabelId, 10), header);
    }
  }

  get labelAutomaticColorList() {
    const res: string[] = [];
    this.metrics.keys.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(this.labelsByIds[k].color)].lighten2));
    });
    return res;
  }
  get labelManualColorList() {
    const res: string[] = [];
    this.metrics.keys.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(this.labelsByIds[k].color)].base));
    });
    return res;
  }
  get predictionCountList() {
    const res: number[] = [];
    this.metrics.keys.forEach((k) => {
      res.push(this.predictions[k]);
    });
    return res;
  }

  get labelAutomaticBarData() {
    return {
      x: this.labelNames,
      y: this.predictionCountList,
      type: "bar",
      name: "Automatic",
      colorscale: "Viridis",
      marker: {
        color: this.labelAutomaticColorList,
      },
    };
  }
  get labelManualBarData() {
    return {
      x: this.labelNames,
      y: this.labelCountList,
      type: "bar",
      name: "Manual",
      colorscale: "Viridis",
      marker: {
        color: this.labelManualColorList,
      },
    };
  }
  get labelBarLayout() {
    return {
      title: "Labels",
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      barmode: "stack",
      xaxis: {
        title: {
          text: "Label",
        },
        ticks: "",
        showgrid: true,
        zeroline: false,
        showline: true,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
      },
      yaxis: {
        title: {
          text: "Count",
        },
        ticks: "",
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
    };
  }

  get labelNames() {
    const mappedNames: string[] = [];
    this.metrics.keys.forEach((k) => {
      const filteredLabels = this.labels.filter((label) => label.id === k);
      if (filteredLabels.length > 0) {
        mappedNames.push(filteredLabels[0].name);
      }
    });
    return mappedNames;
  }
  public labelName(labelId) {
    const filteredLabels = this.labels.filter((label) => label.id === labelId);
    return filteredLabels[0].name;
  }

  get annotations() {
    const annotationList: any[] = [];
    for (let i = this.metrics.cm.length - 1; i >= 0; i--) {
      for (let j = this.metrics.cm[i].length - 1; j >= 0; j--) {
        const currentValue = this.metrics.cm[i][j];
        const result = {
          xref: "x1",
          yref: "y1",
          x: j,
          y: i,
          text: currentValue,
          font: {
            family: "Arial",
            size: 12,
            color: "white",
          },
          showarrow: false,
        };
        annotationList.push(result);
      }
    }

    return annotationList;
  }

  get texts() {
    let predicted: string = "";
    let actual: string = "";
    let annotationList: any[][] = [];
    this.metrics.cm.forEach((dim0Value, dim0) => {
      let text: any[] = [];
      dim0Value.forEach((dim1Value, dim1) => {
        // Sorry, it was the best I could come up with
        if (dim1 > 0) {
          predicted = "Other";
        } else if (dim1 < 1) {
          predicted = this.labelName(parseInt(this.targetLabelId, 10));
        }

        if (dim0 > 0) {
          actual = this.labelName(parseInt(this.targetLabelId, 10));
        } else if (dim0 < 1) {
          actual = "Other";
        }

        text = [
          ...text,
          `Predicted: ${predicted} <br />Actual: ${actual} <br />Count: ${dim1Value}`,
        ];
      });
      annotationList = [...annotationList, text];
    });
    return annotationList;
  }

  get plotData() {
    return [
      {
        z: this.metrics.cm,
        y: ["Other", this.labelName(parseInt(this.targetLabelId, 10))],
        x: [this.labelName(parseInt(this.targetLabelId, 10)), "Other"],
        type: "heatmap",
        hoverinfo: "text",
        text: this.texts,
        colorscale: "Viridis",
      },
    ];
  }

  get layout() {
    return {
      title: "Confusion matrix",
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      annotations: this.annotations,
      xaxis: {
        title: {
          text: "Predicted",
        },
        automargin: true,
        ticks: "",
        showgrid: true,
        zeroline: false,
        showline: true,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
      },
      yaxis: {
        title: {
          text: "Actual",
        },
        automargin: true,
        ticks: "",
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
      zaxis: {
        title: {
          text: "Count",
        },
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
    };
  }

  @Emit()
  public confusion(actualLabelId, predictedLabelId, header) {}
}
