
import { Component, Vue, Watch} from "vue-property-decorator";
import { Store } from "vuex";
import { api } from "@/api";
import { ILabel, IMetrics, IModel, IAPICredentials } from "@/interfaces";
import {
  readMetrics,
  readPreviewItems,
  readModels,
  readLabels,
  readItems,
  readItem,
  readModel,
  readPrediction,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readFirstLabelContainer,
  readLabelColorsByIds,
  readLabelsByIds,
  readLabelsByName,
  readTotalPredictions,
  readLabelCountsByIds,
  readPredictionsByIds,
  readTarget,
} from "@/store/model/getters";
import {
  dispatchGetMetrics,
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetRecommendation,
  dispatchSetPreviewHeader,
} from "@/store/model/actions";

import { readUserProfile, readHasAdminAccess, readToken } from "@/store/main/getters";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ConfusionMatrixMultiLabel from "@/components/ConfusionMatrixMultiLabel.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import { customerApiUrl, authUrl } from "@/env";
import LabelChart from "@/components/LabelChart.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
    ConfusionMatrixMultiLabel,
    RoundProgress,
    LabelChart,
  },
})
export default class MultiLabelMetrics extends Vue {
  public metricThreshold: number = 0.5;
  public loading: boolean = false;
  public totalSupport: number = 0;
  public currentLabelId: number = 0;
  public totalValSet: number = 0;
  public setType: string = "train"; // dictates wether you get train or val metrics
  public loadingConfusion: boolean = false;
  public labelId: number | null = null;

  @Watch('$route', { immediate: true, deep: true })
  public async onRouteChange(to, from) {
    if (to && from && to.params.labelid !== from.params.labelid) {
      this.labelId = parseInt(to.params.labelid, 10);
      this.getMetrics();
      this.loadingConfusion = false;
    }
  }

  public async mounted() {
    this.labelId = parseInt(this.$router.currentRoute.params.labelid, 10);
    await this.getMetrics();
  }

  public async getConfusionMatrixItems(actualLabelId, predictedLabelId, header) {
    this.loadingConfusion = true;
    let trainSet: boolean = true;
    if (this.setType === "validation") {
      trainSet = false;
    }
    this.$router
      .push({
        path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/dashboard/metrics/${this.$router.currentRoute.params.labelid}/preview?actual=${actualLabelId}&predicted=${predictedLabelId}&threshold=${this.metricThreshold}&train=${trainSet}`,
      })
      .catch(() => {
        this.loadingConfusion = false;
      });
  }

  public async switchSet() {
    this.metricThreshold = 0.5;
    await this.getMetrics();
    this.totalValSet = this.totalSupport;
  }

  get predictionsByIds() {
    const predcounts = readPredictionsByIds(this.$store);
    // check if empty (js things)
    if (Object.keys(predcounts).length !== 0) {
      return predcounts;
    }

    // create empty if not
    let res: any = {};
    if (this.labels) {
      this.labels!.forEach((l) => {
        res = { ...res, ...{ [l.id]: 0 } };
      });
    }

    return res;
  }
  get metrics() {
    const met = readMetrics(this.$store);
    return met;
  }

  get labelsByName() {
    return readLabelsByName(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelsByIds() {
    return readLabelsByIds(this.$store)(+this.$router.currentRoute.params.id);
  }

  get token() {
    return readToken(this.$store);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  public async getMetrics() {
    this.loading = true;

    let trainSet: boolean = true;
    if (this.setType === "validation") {
      trainSet = false;
    }

    await dispatchGetMetrics(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      threshold: this.metricThreshold,
      labelId: this.labelId,
      train: trainSet,
    });
    await dispatchGetPredictions(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      threshold: this.metricThreshold,
    });
    this.loading = false;
  }

  /*    ________________________________________________     */
  /*                   metrics table                         */
  /*    ________________________________________________     */
  get tableRows() {
    const rows: any[] = [];
    if (this.metrics) {
      this.totalSupport = 0;
      this.labels!.forEach((v, k) => {
        this.totalSupport += this.metrics!.support[v.id];
        rows.push({
          label: v.name,
          precision: this.metrics!.precision[v.id],
          recall: this.metrics!.recall[v.id],
          f1: this.metrics!.f1[v.id],
          support: this.metrics!.support[v.id],
        });
      });
    }
    return rows;
  }
  get tableHeader() {
    return [
      {
        text: "Labels",
        align: "left",
        value: "label",
      },
      { text: "Precision", value: "precision", align: "right" },
      { text: "Recall", value: "recall", align: "right" },
      { text: "F1", value: "f1", align: "right" },
      { text: "Support", value: "support", align: "right" },
    ];
  }
}
