
import { Component, Vue, Ref } from "vue-property-decorator";
import { Store } from "vuex";
import { IModel, ILabelContainerCreate } from "@/interfaces";
import { readModel, readFirstLabelContainer } from "@/store/model/getters";
import { dispatchCreateLabelContainer } from "@/store/model/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

@Component
export default class CreateLabelContainer extends Vue {
  public id: number = 1;
  public labelContainerName: string = "";
  public dialogLabelContainer: boolean = true;
  public loading: boolean = false;
  public uploadError: any = null;
  public multiLabel: boolean = false;
  @Ref("taskquestion") public readonly taskQuestion!: HTMLInputElement;

  public mounted() {
  }

  get isAdmin() {
    return readHasAdminAccess;
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelContainerNameExists(): boolean {
    return false;
  }

  get labelContainerNameErrors(): string[] {
    if (this.labelContainerNameExists) {
      return ["This name does already exist"];
    }
    return [];
  }

  get labelContainerIsClean(): boolean {
    if (this.labelContainerName.length < 1 || this.labelContainerName.length > 80) {
      return false;
    }
    if (this.labelContainerNameExists) {
      return false;
    }
    return true;
  }

  public createLabelContainerAPI() {
    let labelContainerType: string = "";
    if (this.multiLabel === false) {
      labelContainerType = "single";
    } else {
      labelContainerType = "multi";
    }
    const labelContainer: ILabelContainerCreate = {
      name: this.labelContainerName,
      type: labelContainerType,
    };
    dispatchCreateLabelContainer(this.$store, {
      modelId: this.model!.id,
      labelContainer,
    })
      .then((r) => {
        this.$router.push({
          path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/label/labels/new`,
        });
        this.labelContainerName = "";
        this.uploadError = null;
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.uploadError = uploadError.response;
      });
  }
}
