
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IModel, ILabelContainerCreate, ILabelContainerUpdate } from "@/interfaces";
import { readModel, readFirstLabelContainer, readLabels } from "@/store/model/getters";
import { dispatchCreateLabelContainer, dispatchUpdateLabelContainer } from "@/store/model/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

@Component
export default class CreateLabelContainer extends Vue {
  public id: number = 1;
  public labelContainerName: string = "";
  public dialogLabelContainer: boolean = true;
  public loading: boolean = false;
  public tab: any = null;

  get isAdmin() {
    return readHasAdminAccess;
  }

  get woot() {
    return "woot";
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelContainerNameExists(): boolean {
    return false;
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelContainerNameErrors(): string[] {
    if (this.labelContainerNameExists) {
      return ["This name does already exist"];
    }
    return [];
  }

  get labelContainerIsClean(): boolean {
    if (this.labelContainerName.length < 1 || this.labelContainerName.length > 80) {
      return false;
    }
    if (this.labelContainerNameExists) {
      return false;
    }
    return true;
  }

  public updateLabelContainerAPI() {
    const labelContainer: ILabelContainerUpdate = {
      name: this.labelContainerName,
      type: null,
    };
    dispatchUpdateLabelContainer(this.$store, {
      modelId: this.model!.id,
      labelContainerId: parseInt(this.$router.currentRoute.params.labelcontainerid, 10),
      labelContainer,
    }).then((r) => {
      this.$router.push({
        path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/dashboard`,
      });
      this.labelContainerName = "";
    });
  }
}
