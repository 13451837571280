
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";

@Component
export default class LabelChart extends Vue {
  @Prop({ default: false })
  public metrics!: IMetrics;
  @Prop({ default: false })
  public labels!: ILabel[];
  @Prop({ default: false })
  public predictions!: any;
  @Prop({ default: false })
  public labelsByIds!: any;
  @Prop({ default: false })
  public labelsByName!: any;
  @Prop({ default: false })
  public modelId!: any;
  // public async mounted() {
  //   var myPlot = document.getElementById('plotly-cool');
  //   myPlot?.addEventListener("Click", function (data) {console.log(data)});
  // }

  public handlePlotClick(data) {
    const header =
      "Predicted label:<" +
      this.labelsByName[data.points[0].y].name +
      "> Actual: <" +
      this.labelsByName[data.points[0].x].name +
      ">";
    this.confusion(
      this.labelsByName[data.points[0].y].id,
      this.labelsByName[data.points[0].x].id,
      header,
    );
  }

  get labelCountList() {
    const res: number[] = [];
    this.labels.forEach((k) => {
      res.push(k.counter);
    });
    return res;
  }
  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }
  get labelAutomaticColorList() {
    const res: string[] = [];
    this.labels.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(k.color)].lighten2));
    });
    return res;
  }
  get labelManualColorList() {
    const res: string[] = [];
    this.labels.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(k.color)].base));
    });
    return res;
  }
  get predictionCountList() {
    const res: number[] = [];

    this.labels.forEach((v, k) => {
      res.push(this.predictions[v.id]);
    });
    return res;
  }

  get labelAutomaticBarData() {
    return {
      x: this.labelNames,
      y: this.predictionCountList,
      type: "bar",
      name: "Predicted",
      colorscale: "Viridis",
      marker: {
        color: this.labelAutomaticColorList,
      },
    };
  }
  get labelManualBarData() {
    return {
      x: this.labelNames,
      y: this.labelCountList,
      type: "bar",
      name: "Added by you",
      colorscale: "Viridis",
      marker: {
        color: this.labelManualColorList,
      },
    };
  }
  get labelBarLayout() {
    return {
      title: "Labels",
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      barmode: "stack",
      xaxis: {
        type: 'category',
        title: { text: "Labels", standoff: 50 },
        ticks: "",
        automargin: true,
        showgrid: true,
        zeroline: false,
        showline: true,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
      },
      yaxis: {
        title: {
          text: "Count",
        },
        rangemode: "nonnegative",
        ticks: "",
        tickformat: ",d",
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
    };
  }

  get labelNames() {
    const mappedNames: string[] = [];
    this.labels.forEach((k) => {
      const filteredLabels = this.labels.filter((label) => label.id === k.id);
      if (filteredLabels.length > 0) {
        mappedNames.push(filteredLabels[0].name);
      }
    });
    return mappedNames;
  }

  get annotations() {
    const annotationList: any[] = [];
    if (this.metrics.keys.length <= 30) {
      for (let i = this.metrics.cm.length - 1; i >= 0; i--) {
        for (let j = this.metrics.cm[i].length - 1; j >= 0; j--) {
          const currentValue = this.metrics.cm[i][j];
          const result = {
            xref: "x1",
            yref: "y1",
            x: j,
            y: i,
            text: currentValue,
            font: {
              family: "Arial",
              size: 12,
              color: "white",
            },
            showarrow: false,
          };
          annotationList.push(result);
        }
      }
    }

    return annotationList;
  }

  get texts() {
    let annotationList: any[][] = [];
    this.metrics.cm.forEach((dim0Value, dim0) => {
      let text: any[] = [];
      dim0Value.forEach((dim1Value, dim1) => {
        text = [
          ...text,
          `Predicted: ${this.labelNames[dim0Value.length - dim1 - 1]} <br />Actual: ${
            this.labelNames[dim0]
          } <br />Count: ${dim1Value}`,
        ];
      });
      annotationList = [...annotationList, text];
    });
    return annotationList;
  }

  get plotData() {
    const reversed = this.labelNames.slice();
    return [
      {
        z: this.metrics.cm,
        y: this.labelNames,
        x: reversed.reverse(),
        type: "heatmap",
        hoverinfo: "text",
        text: this.texts,
        colorscale: "Viridis",
      },
    ];
  }

  get layout() {
    return {
      title: "Confusion matrix",
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      annotations: this.annotations,
      xaxis: {
        title: {
          text: "Predicted",
        },
        ticks: "",
        showgrid: true,
        zeroline: false,
        showline: true,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
      },
      yaxis: {
        title: {
          text: "Actual",
        },
        ticks: "",
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
      zaxis: {
        title: {
          text: "Count",
        },
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
    };
  }

  @Emit()
  public confusion(actualLabelId, predictedLabelId, header) {}
}
